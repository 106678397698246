@import 'tailwindcss';
@import '@cdl/heartbeat-tokens/holter';
@import './hb-tailwind-theme.css';
@import './hb-typography.css';

@layer utilities {
  .border {
    border-width: 0.01rem;
  }
}

@theme {
  --spacing: calc(var(--spacing-xxs) / 2);
}
